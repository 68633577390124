// Classes
import FieldsOptions         from '@/Classes/Records/FieldsOptions'
import { ItemMissingFields } from '@/Classes/Records/ItemMissingFields'
import { BuilderManager }    from '@/Classes/Responses/BuilderManager'
import { PrimitiveTools }    from '@/Classes/Static/PrimitiveTools'
import { ActionFields } from '@/Constants/Global/ActionFields'

// Constants
import { AppValues } from '@/Constants/Global/AppValues'
import { Module3 }   from '@/Constants/Modules/Module3'

// Store
import Store from '@/Store/Global/Default'

export function parseDataResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, Module3.M30.ActionFields.Services)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, [
		'_idService', '_idEquipment', '_idStorage', '_idClient', '_idTechnical',
		'storageCode','clientName','clientLastName','technicalLastName','Description','details',
		'dateProgram','status'


	])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
    .add('dateCreation', 'Fecha solicitud').isSortable().setAlign(null, 'center').setSortDirection('desc')
    .add('storageName', 'Planta').isSortable().setAlign(null, 'center')
    .add('equipmentCode', 'Código Equipo').isSortable().setAlign(null, 'center')
    .add('equipmentName', 'Equipo').isSortable().setAlign(null, 'center')
    .add('clientEmail', 'Correo').isSortable().setAlign(null, 'center')
    .add('technicalName', 'Nombre Técnico').isSortable().setAlign(null, 'center')
    .add('statusEquipment', 'Estatus').isSortable().setAlign(null, 'center')
)



	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields(fields, ['clientEmail', 'storageName', 'equipmentName', 'equipmentCode', 
		 'status', 'statusEquipment', 'dateCreation', 'technicalName'])



	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(items, new ItemMissingFields()
		.add('dateProgram')
		
	)

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters(items, {
		
		technicalName: (v: void, item: any) => {
			return `${ item.technicalName } ${ item.technicalLastName }`
		},

		dateCreation: (v: void, item:any) =>{
			const date = item.dateCreation
			const _date = PrimitiveTools.Dates.parseDateString(date)
			return _date ? `${ _date.date } ${ _date.time }` : AppValues.Strings.DEFAULT_EMPTY_STRING
		}
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}
