// ./
import { parseDataResponse } from './response.parser'

// Classes
import { JoiManager }     from '@/Classes/Network/JoiManager'
import { DataParsers }    from '@/Classes/Responses/DataParsers'
import { PrimitiveTools } from '@/Classes/Static/PrimitiveTools'
import { VuexTools }      from '@/Classes/Static/VuexTools'

// Components
import BasicHeader             from '@/Components/Global/BasicHeader/template.vue'
import DataTable               from '@/Components/Global/DataTable/template.vue'
import { DataTableRef }        from '@/Components/Global/DataTable/component'
import InitialsButton          from '@/Components/Global/InitialsButton/template.vue'
import PopupTable              from '@/Components/Global/PopupTable/template.vue'
import { PopupTableRef }       from '@/Components/Global/PopupTable/component'
import FilterBar               from '@/Components/Modules/3/FilterBar/template.vue'
import PopupComment            from '@/Components/Modules/3/PopupComment/template.vue'
import { PopupCommentRef }     from '@/Components/Modules/3/PopupComment/component'
import PopupServiceForm        from '@/Components/Modules/3/PopupServiceForm/template.vue'
import { PopupServiceFormRef } from '@/Components/Modules/3/PopupServiceForm/component'

// Constants
import { AppValues } from '@/Constants/Global/AppValues'
import { Component } from '@/Constants/Global/Component'
import { Documents } from '@/Constants/Global/Documents'
import { Server }    from '@/Constants/Global/Server'
import { VueRouter } from '@/Constants/Global/VueRouter'
import { Vuex }      from '@/Constants/Global/Vuex'
import { Module3 }   from '@/Constants/Modules/Module3'

// Dependencies
import { Socket } from 'socket.io-client'
import VueMixins  from 'vue-typed-mixins'

// Mixins
import MixinBase      from '@/Mixins/MixinBase'
import MixinComponent from '@/Mixins/MixinComponent'
import MixinFetch     from '@/Mixins/MixinFetch'

// Store
import Store from '@/Store/Global/Default'
import PopupUserForm, { PopupUserFormRef } from '@/Components/Modules/2/PopupUserForm/component' // En prueba
import PermissionsForm, { PermissionsFormRef } from '@/Components/Modules/2/PermissionsForm/component' // En prueba

// Component Extend
const View30 = VueMixins(MixinBase, MixinComponent, MixinFetch).extend({
	name: VueRouter.Modules.View30.NAME,

	components: {
		BasicHeader,
		DataTable,
		PopupTable,
		PopupUserForm,
		PermissionsForm,

	},

	data: function() {
		return {
			states: {
				dataFormAction: Component.Actions.INSERT,
				dataTableParser: parseDataResponse,
				documentToUpdate: undefined,
				inputButtonKey: undefined,
				showDataTable: true,
				showPopupUserForm: false,
				tempPermissions: undefined,
				userStorages: []
			}
		}
	},

	created: function() {
		
	},

	mounted: function() {
		this._initDataTable()
		this._initPermissions()
		//this._popupUserForm._dataTable.setStates<DataTableRef['states']>({ preventDefaultStacked: true, showRefreshButton: false })
	},

	beforeDestroy: function() {
		
	},

	computed: {

		_dataTable: function(): DataTableRef {
			return this.$refs.dataTable as DataTableRef
		},

		_permissionsForm: function(): PermissionsFormRef {
			return this.$refs.permissionsForm as PermissionsFormRef
		},
	},

	methods: {
		_initDataTable: async function() {
			const { stacked } = this._dataTable.states

			const response = await Store.dispatch('fetchServicesForPage', {  page:1 })
			console.log("RESPOnse INIT", response);
			const dataTableParser = stacked ? '' : parseDataResponse
			const { fields, items, actions } = this.states.dataTableParser(response.data)
			this._dataTable.setStates<DataTableRef['states']>({ exportDataAsAsync: true, isLocalSearch: false })
			this._dataTable.updateElementsAndPagination(response.totalPages, fields, items, actions)
		},

		_fetchServices: async function(page: number) {
			const user = Store.getters.getStoredUser
			
				const _idAdminCompany = user._idAdminCompany
			// Realizar la petición al Servidor.
			const params = { _idAdminCompany, itemsPerPage: this._dataTable.itemsPerPage, page }
			const response = await this.doFetch({ action: Server.Fetching.Method.GET, path: Server.Routes.ServicesRequest.GetServicesAll, params })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				return response.data.body[0]
			}
		},

		onDTButtonClick: function(key: string, row: any) {
			if (key === 'edit') {
				console.log("EDITAR");
				// this._popupUserForm._dataForm.setStates<DataFormRef['states']>({ action: Component.Actions.UPDATE })
				// this.setStates<View30Ref['states']>({ dataFormAction: Component.Actions.UPDATE, documentToUpdate: row.item, showPopupUserForm: true })
				// this._updateDataForm()
			}
		},

		_updateDataForm: function() {
		}, 

		_initPermissions: function() {
			const userPermissions = Store.getters.getStoredUserPermissionsAsObject
			this._dataTable.setPermission('ACTION_EDIT', userPermissions.MODULE_20_MANAGE_INTERNAL_USERS?.privileges.write)
			this._dataTable.setPermission('NEW_BUTTON', userPermissions.MODULE_20_MANAGE_INTERNAL_USERS?.privileges.write)
		},

		onDTPaginationChanged: async function(page: number) {
			// La Pestaña actual activa.

			
			// Realizar la petición hacia el servidor.
				const response = await this._fetchServices( page)
				console.log("RESPUESTA DEL CANBUI", response);

				const data = Array.isArray(response) ? response : response.data
				const { fields, items, actions } = this.states.dataTableParser(data)
				this._dataTable.updateElementsAndPagination(response.totalPages, fields, items, actions)
				Store.commit('storeServicesForPage', { data, page, totalPages: response.totalPages })
		},

		onDTExportDataClick: async function(format: string, filterType: AppValues.DataTableExportDataFilterType, filterValue: AppValues.PeriodsChoiceList) {
			console.log("DARAAAA");
			const user = Store.getters.getStoredUser
			const _idAdminCompany = user._idAdminCompany
			const params = {
				_idAdminCompany,
				itemsPerPage: 1,		// 'itemsPerPage' es Requerido en la Petición pero no es considerada.
				page: 1,				// 'page' es Requerido en la Petición pero no es considerada.
				period: filterValue
			}

			// 'Parser' utilizado para procesar la Respuesta de los Datos.
			const { dataTableParser } = this.states

		
				// Realizar la Petición al servidor.
				const response = await this.doFetch({ action: Server.Fetching.Method.GET, path: Server.Routes.ServicesRequest.GetServicesAll, params })

				// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
				if (response.status === Server.Response.StatusCodes.SUCCESS) {
					// Respuesta de la Petición.
					const _response = response.data.body.data
					const { items } = dataTableParser(_response)
					this._dataTable.handleExportDataType(format, items)
				}
			
		},

		/* <=================|=============================|==================> */
		/* <=================| EVENT DECLARATION FUNCTIONS |==================> */
		/* <=================|=============================|==================> */

	},

	watch: {
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Permissions, '_initPermissions'),
	}
})

// Exports
export default View30
export type View30Ref = InstanceType<typeof View30>