import { render, staticRenderFns } from "./template.vue?vue&type=template&id=327b858e&scoped=true&"
import script from "@/Views/Modules/3/30/component.ts?vue&type=script&lang=ts&"
export * from "@/Views/Modules/3/30/component.ts?vue&type=script&lang=ts&"
import style0 from "@/Views/Modules/3/30/style.scss?vue&type=style&index=0&id=327b858e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "327b858e",
  null
  
)

export default component.exports